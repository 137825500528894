import { Content } from "@/ui";
import { createTitle } from "@/functions/createTitle";
import { homepage_quote_helper } from "@/helpers/homepage_quote_helper";
import { homepage_tiles_helper } from "@/helpers/homepage_tiles_helper";
import { jobs_helper } from "@/helpers/jobs_helper";

export default function Page({ content }) {
  return (
    <>
      <Content items={content} />
    </>
  );
}

export async function getStaticProps({ }) {
  return {
    props: {
      meta: {
        title: createTitle("Home"),
      },
      content: [
        {
          component: "Hero",
          props: {
            title: {
              path: "page.home.component.Hero.title",
              placeholder: "Personalised relationships, Sector specialisms, Global presence",
            },
            videos: [
              { video_embed_url: "/homepage-videos/nurses-video.mp4" },
              { video_embed_url: "/homepage-videos/male-nurse-video.mp4" },
              { video_embed_url: "/homepage-videos/physio-video.mp4" },
              { video_embed_url: "/homepage-videos/mental-health-video.mp4" },
            ],
          },
        },
        // {
        //   component: "MiniCarousel",
        //   props: {
        //     className: "my-4 my-md-5 text-white",
        //     title: { path: "page.home.component.MiniCarousel.title", placeholder: "" },
        //     items: mini_carousel_helper.fetch({
        //       filter: (i) => i.tags.toLowerCase().includes("home") || i.tags.includes("*"),
        //     }),
        //   },
        // },
        {
          component: "RichText",
          props: {
            title: {
              tag: "h3",
              className: "text-primary",
              path: "page.home.component.RichText.1.title",
              placeholder:
                "We provide locum, temporary and permanent nursing, healthcare assistants, midwives, and allied health recruitment in region for resident and overseas-trained professionals.",
            },
            body: {
              path: "page.home.component.RichText.1.body",
              placeholder:
                "We have more than 20 years of experience and expertise in medical recruitment in the region.The core of our business is our commitment to the provision of exceptional, personalised service. We apply a high level of quality control with all our healthcare jobs to ensure we continue to be the premier provider of jobs for nurses, midwives, and allied health professionals.",
            },
          },
        },
        {
          component: "HomepageTiles",
          props: {
            className: "py-3",
            items: homepage_tiles_helper.fetch(),
          },
        },
        {
          component: "LatestJobs",
          props: {
            className: "py-5",
            title: { path: "page.home.component.LatestJobs.1.title", placeholder: "Latest Jobs" },
            items: jobs_helper.fetch({ limit: 12 }),
            visibleCount: 4,
            button: {
              label: "View all jobs",
              href: "/jobs/",
            },
          },
        },
        {
          component: "BlockQuote",
          props: {
            className: "bg-primary text-white py-4 py-md-5",
            items: homepage_quote_helper.fetch({ limit: 12 }).map((quote) => ({
              quote: quote.quote,
              author: {
                name: quote.author_name,
                position: quote.author_postion,
              },
            })),
            visibleCount: 1,
            carousel: true,
          },
        },
      ],
    },
  };
}
